import React from 'react';


class HomeMap extends React.Component {
    constructor(props) {
        super(props);
        window.homeMapObj = null;

    }
    componentDidMount() {

        let mapZoomOptions = {};

        if (this.props.data.mapZoomOptions)
            mapZoomOptions = this.props.data.mapZoomOptions;

        window.homeMapObj = new window.LiveMap({
            isOldMapServer: this.props.data.isOldMapServer || false,
            defaultZoomLevel: mapZoomOptions.Default || 3,
            minZoom: mapZoomOptions.Min || 2,
            maxZoom: mapZoomOptions.Max || 17,
            mapServerUrl: this.props.data.mapUrl,//'https://wmsserver.costa.it',
            mapServerName: "SevenCsWMS",
            elementId: "homemap",
            noMousePosition: false,
            noLayersSwitcher: true,
            noMeteoSwitcher: true,
            noScaleBar: true,
            noWeatherBar: true,
            noECA: true,
            noMeasure: true,
            routeEditing: false,
            KTWMSMinZoom: 3,
            minZoomAis: 10,
            minZoomTargetRadar: 10,
            minZoomToWaypointsLabels: 8,
            hideWaypoints: true,
            minZoomToShipContour: 100,
            onLoadEnd: this.props.handleEnableMap,
            onLongClick: this.props.onLongClick
        });

        this.updateMap();
    }

    componentDidUpdate(prevProps) {
        this.updateMap(prevProps);
        //window.homeMapObj.setDefaultView();
    }

    updateMap = (prevProps) => {

        const data = this.props.data;
        const ships = data.ships;
        const isUpdateShip = !prevProps || prevProps.data.ships !== ships ? true : false;

        if (ships && isUpdateShip)
            ships.forEach(ship => window.homeMapObj.updateShip(ship.Id, ship.Name, ship.Lon, ship.Lat, ship.BrandCode, null, null));

    };

    render() {
        return (
            <React.Fragment>
                <div id="homemap" className="map on"></div>
            </React.Fragment>

        );
    }
}

export default HomeMap;