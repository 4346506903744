import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import HomeMap from './HomeMap';
import './LiveHome.scss';
import _ from 'lodash';

class LiveHome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: '',
            openedPanel: false,
            minimizePanel: false,
            openMenu: false,
            status: 'All',
            txtSearch: '',
            selectedBrand: ''
        }

        
    }
    componentDidMount() {
        window.qstring = window.location.search;
        const qs = new URLSearchParams(window.location.search);
        //let subType = "";
        //if (qs.has("alertSubType"))
        //    subType = qs.get("alertSubType");

        //this.setState({ shipId: qs.get("shipId"), alertId: qs.get("alertId"), alertSubType: subType, token: qs.get("token") });
        this.props.handleInitLoading();
        this.props.fetchRecentShips();
        this.setState({ token: qs.get("token") }, this.fetchData);
        //this.setState({ token: qs.get("token") });
    }

    componentWillUnmount() {
        clearTimeout('home');
    }
    
    fetchData = () => {

        this.props.fetchHomeData().then(data => {
            if ((window.activeTimer('home') === undefined))
                setTimeout(this.fetchData, data.updateInterval, 'home');
        });
        
    }

    togglePanel = () => {
        this.setState({ openedPanel: !this.state.openedPanel, minimizePanel: false });
    }

    toggleMinimizePanel = () => {
        if (this.state.openedPanel)
            this.setState({ openedPanel: !this.state.openedPanel });
        else
            this.setState({ openedPanel: false, minimizePanel: !this.state.minimizePanel });
    }

    toggleDropDown = () => {
        this.setState({ openMenu: !this.state.openMenu});
    }

    handleChangeSearch = (element) => {
        this.setState({ txtSearch: element.target.value});
    }

    handleClearSearch = () => {
        this.setState({ txtSearch: '' });
    }

    handleChangeStatusFilter = (element) => {
        this.setState({ status: element.target.textContent });
        this.toggleDropDown();
    }

    handleSelectBrand = (brand) => {
        const selectedBrand = brand === this.state.selectedBrand ? '' : brand;
        this.setState({ selectedBrand }, () => {
            if (!this.state.openedPanel)
                this.togglePanel();

            if (selectedBrand.length > 0)
                this.scrollToNode(this[selectedBrand]);
        });
    }

    goToShip = shipId => {
        this.props.changeShip(shipId)
        this.props.history.push(`/live/ship/?shipId=${shipId}&token=${this.state.token}`);
    }

    scrollToNode = (node) => {
        node.scrollIntoView({ behavior: 'smooth' });
    }

    getPanelClass = () => {
        if (!this) return;
        let panelClass = "slideHomePanel";
        if (this.state.openedPanel || !this.props.isMapEnabled) {
            panelClass += " open";
        } else if (this.state.minimizePanel) {
            panelClass += " minimize";
        }

        return panelClass;
    }

    render() {
        const { data, handleEnableMap, isMapEnabled, recent } = this.props;
        var navigationStatusList = null;
        var mapCompenent, brandsList;

        const ships = data && data.Brands ? _.flatMap(data.Brands, (b) => b.Ships) : [];

        const recentShips = recent.filter(r => ships.find(s => s.Id === r) !== undefined);

        if (data.mapUrl && data.Brands) {
            const brands = data.Brands;

            mapCompenent = <HomeMap data={{ mapUrl: data.mapUrl, ships, mapZoomOptions: data.mapZoomOptions, isOldMapServer: data.isOldMapServer }} handleEnableMap={handleEnableMap} onLongClick={this.goToShip} />;

            brandsList = brands.map((brand, index) => {
                let shipsList = null;
                //let ships = this.state.txtSearch.length === 0 ? brand.Ships : brand.Ships.filter(ship => ship.Name.toLowerCase().indexOf(this.state.txtSearch.toLowerCase()) !== -1);
                let _ships = brand.Ships.filter(ship => this.state.status === 'All' ? true : ship.NavigationStatusName === this.state.status).filter(ship => ship.Name.toLowerCase().indexOf(this.state.txtSearch.toLowerCase()) !== -1);

                if (this.state.selectedBrand === brand.BrandCode || this.state.txtSearch.length > 0) {
                    shipsList = <ul className="ships">
                        {_.orderBy(_ships, [ship => ship.Name.toLowerCase()]).map((ship, idx) => <li key={idx} onClick={() => this.goToShip(ship.Id)}>{ship.Name}</li>)}
                    </ul>
                }

                if (_ships.length === 0)
                    return null;

                return <React.Fragment key={index}>
                    <div className="brand" ref={(node) => this[brand.BrandCode] = node} onClick={() => this.handleSelectBrand(brand.BrandCode)}>{brand.BrandName}</div>
                    {shipsList}
                </React.Fragment>
            });
        }

        if (data.NavigationStatus) {
            navigationStatusList = data.NavigationStatus.map((navStatus, index) => <li className="item" key={index} onClick={this.handleChangeStatusFilter}>{navStatus}</li>);
        }

        return (
            <React.Fragment>
                {mapCompenent}
                <div className={this.getPanelClass()}>
                    <div className="slideCircleButton" onClick={this.togglePanel}>
                        <i className="icon-ship-position"></i>
                    </div>
                    <div className="header" onClick={this.toggleMinimizePanel}>
                        <div>
                            {isMapEnabled ? <span className="slideButton"></span> : null}
                        </div>
                    </div>
                    <div className="content">
                        {recentShips && recentShips.length > 0 ? <React.Fragment>
                            <span className="label">Recent Ships</span>
                            <div className="rowContent">
                                <div className="recentShips">
                                    {recentShips.map((item, index) => {
                                        const brandShip = item.split('-');
                                        return <div className="cirle" key={index} onClick={() => this.goToShip(item)}>
                                            <div className="brand">{brandShip[0]}</div>
                                            <div className="ship">{brandShip[1]}</div>
                                        </div>
                                    })}

                                </div>
                            </div>
                        </React.Fragment> : null}
                        <div className="rowContent">
                            <div className="search">
                                <i className="icon-search"></i>
                                <input type="text" className="txtsearch" value={this.state.txtSearch} onChange={this.handleChangeSearch} placeholder="Ship" />
                                <i className="clearText icon-close1" onClick={this.handleClearSearch}></i>
                            </div>
                            <div className="statusContent">
                                <div className="status" style={{ visibility: this.state.openMenu ? 'hidden' : '' }}>
                                    <span>{this.state.status}</span>
                                    <i className="icon-menu" onClick={this.toggleDropDown}></i>
                                </div>
                                <ul className="dropdownMenu" style={{ visibility: !this.state.openMenu ? 'hidden' : '' }}>
                                    <li className="item" onClick={this.handleChangeStatusFilter}>All</li>
                                    {navigationStatusList}
                                </ul>
                            </div>
                        </div>
                        <span className="label">All Ships</span>
                        <div className={(!recentShips || recentShips.length === 0) && !this.state.openedPanel ? "brandsShips withoutRecent" : "brandsShips"}>
                            {brandsList}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

export default withRouter(LiveHome);