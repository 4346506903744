import React from 'react';
import BoxRow from './BoxRow';

function DGAAQS(props){
    
    const aaqs = props.DGAAQSDetails;
    var OvbdDischargeDiffThresholds = null;

    if(!aaqs)
        return null;

    if(aaqs.HasPHSensorInletEnabled)
        OvbdDischargeDiffThresholds = "[Diff: " + aaqs.OvbdDischargeDiff	+ " Rack1: " + aaqs.PhAtRack1	+ "]";

    return (
        <div className="box">
            <span className="boxTitle">AAQS</span>
            <span className="engineDate">Last Update: {aaqs.LastUpdateUTCAsText}</span>
            <div style={{clear: "both"}}></div>
            <div className="boxContent">
                <div className="div-table">
                    <BoxRow title="SO2/CO2 RATIO:" value={aaqs.SO2CO2Ratio + " (ppm)/(%v/v)"} />
                    <BoxRow title="PAH Differential:" value={aaqs.PAHDifferential + " ppb"} thresholds={"[Limit: " + aaqs.PAHDifferentialLimit + "]"} />
                    <BoxRow title="Turbidity Differential:" value={aaqs.TurbidityDifferential + " FNU"} thresholds={"[Avg 15 min: " + aaqs.TurbidityDifferentialMin	+ "]"} />
                    <BoxRow title="Overboard Discharge:" value={aaqs.OvbdDischarge + " pH"}  thresholds={OvbdDischargeDiffThresholds}/>
                    <BoxRow title="Sea Water Flow Rate:" value={aaqs.SeaWaterFlowRate} > m<sup>3</sup>/h</BoxRow>
                    <BoxRow title="Buffer Flow Rate:" value={aaqs.BufferFlowRate} > m<sup>3</sup>/h</BoxRow>
                </div>
            </div>
        </div>
    );
       
}

export default DGAAQS;