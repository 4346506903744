import React, { Component } from 'react';
import './Profile.scss';
import MobileButton from './components/MobileButton';

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            email: ''
        }
    }
    componentDidMount() {

        this.props.fetchUserProfile().then(data => {
            this.setState({username: data.UserName, email: data.Email});
        });

    }

    postMessage = message => {
        if (window.ReactNativeWebView)
            window.ReactNativeWebView.postMessage(message);
    }

    render() {

        return (
            <div className="pageContent" id="profile">
                <div className="content">
                    <div className="title">Profile</div>
                    <div className="user">
                        <i className="icon-profile"></i>
                        <span>{this.state.username}</span>
                    </div>
                    <div className="userDetails">
                        <div>
                            <span className="label">E-mail:</span>
                            <span className="value">{this.state.email}</span>
                        </div>
                    </div>
                    {/*<div className="buttons">
                        <div>Select Environment</div>
                        <MobileButton onClick={() => this.postMessage('change-environment-ship')}>SHIP</MobileButton>
                        <MobileButton onClick={() => this.postMessage('change-environment-vpn')}>OFFICE</MobileButton>
                        <MobileButton onClick={() => this.postMessage('change-environment-public')}>PUBLIC</MobileButton>
                    </div>*/}
                    {/*<MobileButton className="logoutbtn" onClick={() => this.postMessage('logout')}><i className="icon-esc"></i>Logout</MobileButton>*/}
                </div>
            </div>
            );
    }
}

export default Profile;