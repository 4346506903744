import React from 'react';

function AshoreConnection(props){
    const _ashoreConnection = props.AshoreConnection;

    if(!_ashoreConnection)
        return null;

    var perc = _ashoreConnection.LoadPercentage || 0;
    var ashoreStatus = null;

    const statusStyle = {
        fontFamily: 'open_sans_condensedbold',
        textTransform: 'uppercase',
        fontSize: '14px',
        position: 'absolute'
    }
    const barStyle = {
        height: '10px',
        border: '1px solid #197288',
        borderRadius: '10px',
        margin: '10px 10px 10px 0px'
    }

    var barValueStyle = {
        height: '10px',
        borderRadius: '10px',
        backgroundColor: '#CE3538',
        width: perc + '%'
    }

    if(_ashoreConnection.Status !== null){
        switch(_ashoreConnection.Status){
            case 0:
                barValueStyle = {
                    height: '10px',
                    borderRadius: '10px',
                    backgroundColor: '#f6f388',
                    width: '10px'
                };
                ashoreStatus = <span style={statusStyle}>UNK</span>;
                break;
            case 1:
                barValueStyle = {
                    height: '10px',
                    borderRadius: '10px',
                    backgroundColor: '#CE3538',
                    width: '10px'
                };
                ashoreStatus = <span style={statusStyle}>OFF</span>;
                break;
            case 2:
                barValueStyle = {
                    height: '10px',
                    borderRadius: '10px',
                    backgroundColor: '#75b936',
                    //backgroundColor: '#CE3538',
                    width: perc + '%'
                };
                ashoreStatus = <span style={statusStyle}>ON</span>;
                break;
            default:
                break;
        }

    }else{
        barValueStyle = {
            height: '10px',
            borderRadius: '10px',
            backgroundColor: '#b2b2b2',
            width: '10px'
        };
        ashoreStatus = <span style={statusStyle}>OFF</span>;
    }



    return (
        <div className="engineContent">
            <span className="engineTitle">Ashore Connection</span>
            <span className="engineDate">Last Update - Prop. Flow: {props.EnergyLastUpdateUTCAsText}</span>
            <div style={{clear: 'both'}}></div>
            <div style={barStyle}>
                <div style={barValueStyle}></div>
            </div>
            {ashoreStatus}
            <div style={{textAlign: 'center'}}><span className="label">Actual Power: </span><span className="value">{_ashoreConnection.ActualPower.Value + _ashoreConnection.ActualPower.QualityAsText} KW</span></div>
            <div style={{clear: 'both'}}></div>
        </div>
    );
}

export default AshoreConnection;