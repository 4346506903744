import React from 'react';

function DgStatus(props){
    const dgLoad = props.DGLoad.length > 0 ? <React.Fragment><span className="label">{props.labelLoad}: </span><span className="value">{props.DGLoad}</span></React.Fragment> : <span className="emptyrow"></span>;
    const fuelType = props.FuelType ? <React.Fragment><span className="label">Fuel Type: </span><span className="value">{props.FuelType}</span></React.Fragment> : <span className="emptyrow"></span>;

    return (
        <React.Fragment>
            <div className="col1">
                <div className={"dgStatus " + props.DGStatusColor}>{props.Name}</div>
            </div>
            <div className="col2">
                <div>{dgLoad}</div>
                <div>{fuelType}</div>
            </div>
        </React.Fragment>
    );
}

export default DgStatus;