import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Settings } from './Settings';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './index.css';

const rootElement = document.getElementById('root');
(function (w) {
    var active = {};

    var _setTimeout = w.setTimeout;
    var _clearTimeout = w.clearTimeout;

    w.setTimeout = function (fn, delay, id) {

        var timerId = _setTimeout(function () {
            fn();
            delete active[id];
        }, delay);
        active[id] = timerId;
        return id;
    }

    w.clearTimeout = function (id) {
        _clearTimeout(active[id]);
        delete active[id];
    }

    w.activeTimer = function (id) {
        //return Object.keys(active).length;
        return active[id];
    }
    w.activeTimers = function () {
        return Object.keys(active);
    }
})(window);

// https://localhost:44435/settings?shipId=C-DI&alertId=546344&token=eyJhbGciOiJSUzI1NiIsImtpZCI6IjEyMEFGMTVBRDM0QTQ0MkE5RjNFNEI1RkM4Q0NDMjE3NDQ2MDI4NjIiLCJ0eXAiOiJKV1QiLCJ4NXQiOiJFZ3J4V3ROS1JDcWZQa3RmeU16Q0YwUmdLR0kifQ.eyJ1cG4iOiJvYnMua3QuMDdAY29zdGEuaXQiLCJpc3MiOiJWUE4iLCJleHAiOjE1Nzk2MTcyNjQsImlhdCI6MTU3OTUzMDg2NCwibmJmIjoxNTc5NTMwODY0fQ.sobx3b_q2om6isxiikZKy3-p34Qs26-5jrFcA1OPsy_NtFiF4kfXpN2jAKxHs9GzDe6HGsSY2oIZpisWkhUtxULtFASVjqW3LyyR_mxMg7qLrQI-OPGl8lZsdu145ibsYudqHpScFxFzIalzavx1pNqiFr_X2HW9OCQYjCyOjZG_B7F7ObdzxUiYZks8q3-Zbs2-hzMZCNmVv8XhNzFUG06fpJmAGciYwXlt5PxezHafnyFZa_ZpLfT9-VV3Q20_cYBt2bpfs4fr--HpKQMOqsfeDaNAvmt-0uk5WqANEDexRLPH7xPLBYl8L5nEiR34SYKHGgqdMl6jaDern16JBo6Js4AFXCqVNSCBVo4Rqm4i3_rBDO7YTEyXFR2FfIpcxr0TJzwzIYErFFQ7NJNSiB_wrJCUquxEKfBfh0PletkiQQnTbRNZDoV4FA4mYBDfmlsCLD7m0QPpfBVltebKJoPFnWM9VI4EvpwveNgxMN863ysrho1norCby2kI_ZfhDhEzLrRuXt0t_nHuwerX2_UlFiJpPZ4iLMhNcxMXz9ldH2XNnSrOPuV2icrR2Zbl8C6ca-GWBYW78dkIAMy9ZO3cz2nvBGg1RIFuPVqGvCjoGATPWd2aX8s5WzWFTs-W35sMbIoGAbl1WxQURZ6hUAEu70l71XLENnLvR7eSkVI

// https://myshiptest.costa.it/notification


let routes = (
    <Switch>
        <Route path="/settings">
            <Settings />
        </Route>
        <Route path="/">
            <App />
        </Route>
    </Switch>
);


ReactDOM.render(
    <Router basename="/">
        {routes}
    </Router>,
    rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
