import React from 'react';
import EngineBox from './EngineBox';

function GasTurbines(props){
    const GTList = props.GTList;

    if(!GTList)
        return null;

    const dgsList = GTList.map((dg, id)=>{
        return <EngineBox labelLoad="GT Load" dg={dg} key={id} handleSelectDG={props.handleSelectDG}/>
    });

    return (
        <div className="engineContent">
            <span className="engineTitle">Gas Turbines</span>
            <div style={{clear: 'both'}}></div>
            {dgsList}
            <div style={{clear: 'both'}}></div>
        </div>
    );
}

export default GasTurbines;