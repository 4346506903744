import React from 'react';

function CurrentParameters(props){

    const tableRows = props.currentParams.map((row, id)=>{
        return <div className="div-table-row" key={id}>
                    <div className="div-table-cell date">{row.date}</div>
                    <div className="div-table-cell"><span>{row.dg}</span></div>
                    <div className="div-table-cell"><span>{row.name}</span></div>
                </div>
    });

        return (
            <div className="box">
                <div className="boxTitle">Current Parameters causing the {props.alarmAlertStr}</div>
                <div className="boxContentAlert">
                    <div className="div-table">
                        <div className="div-table-row-header">
                            <div className="div-table-cell date">Date (GMT)</div>
                            <div className="div-table-cell"><span>DG\Ship</span></div>
                            <div className="div-table-cell"><span>Description</span></div>
                        </div>
                        {tableRows}
                    </div>
                </div>
            </div>
        );
}

export default CurrentParameters;