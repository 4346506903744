import React, { Component } from 'react';
import { Route } from "react-router-dom";
import Map from './Map';
import Unauthorize from './Unauthorize';
import Header from './Header';
import AlertDetail from './AlertDetail';
import EngineStatus from './EngineStatus';
import EngineDetails from './EngineDetails';
import Itinerary from './Itinerary';
import Footer from './Footer';
import Alerts from './Alerts';

class LiveShip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: '',
            openDetailAlert: false,
            shipId: null
        }
    }

    componentDidMount() {
        window.qstring = window.location.search;
        const qs = new URLSearchParams(window.location.search);
        //let subType = "";
        //if (qs.has("alertSubType"))
        //    subType = qs.get("alertSubType");

        //this.setState({ shipId: qs.get("shipId"), alertId: qs.get("alertId"), alertSubType: subType, token: qs.get("token") });
        this.props.handleInitLoading();
        this.props.handleSelectSection('alerts');
        this.setState({ token: qs.get("token"), shipId: qs.get("shipId") }, this.fetchData);
    }

    componentWillUnmount() {
        clearTimeout('ship');
    }

    fetchData = () => {
        this.props.fetchShipData().then(() => {
            if ((window.activeTimer('ship') === undefined))
                setTimeout(this.fetchData, 15000, 'ship');
        });

    }

    setOpenDetailAlert = value => {
        this.setState({ openDetailAlert: value});
    }

    render() {
        const {
            logo,
            handleOpenPanel,
            handleSelectDG,
            openedPanel,
            isMapEnabled,
            fetchEngineStatus,
            engineStatus,
            isLoadingEngine,
            handleEnableMap,
            isFirstLoading,
            fetchVoyageInfo,
            voyageInfo,
            isLoadingItinerary,
            fetchShipImage,
            selectedSection,
            handleSelectSection,
            data,
            user,
            alertSubType,
            dgDetail,
            labelLoad,
            shipImage,
            isUserAuthorized,
            fetchShipData,
            fetchAlertData,
            isOnboard,
            alertDetails} = this.props;

        var mapCompenent;
        if (data.mapUrl)
            mapCompenent = <Map data={data} viewAlertButton={this.state.openDetailAlert} handleEnableMap={handleEnableMap} />;

        if (!isUserAuthorized)
            return (<Unauthorize user={user} />);

        let alert = null;

        if (alertDetails && data.alerts) {
            alert = data.alerts.filter(a => a.Id === alertDetails.id);
            if (alert.length > 0) {
                alert = alert[0];
            }
        }

        return (
            <React.Fragment>
                <Header shipName={data.shipName} logo={logo} isOnboard={isOnboard} />
                <div id="contentBody">
                    {mapCompenent}
                    <Route path='/live/ship/alerts' render={() => <Alerts
                        alerts={data.alerts}/>} />
                    <Route path='/live/ship/alertDetail' render={() => (<AlertDetail
                        handleOpenPanel={handleOpenPanel}
                        handleSelectSection={handleSelectSection}
                        fetchShipData={fetchShipData}
                        openedPanel={openedPanel}
                        isMapEnabled={isMapEnabled}
                        isLoading={isFirstLoading}
                        alertSubType={alertSubType}
                        fetchAlertData={fetchAlertData}
                        alertDetails={alertDetails}
                        setOpenDetailAlert={this.setOpenDetailAlert}
                        alert={alert}
                    />)} />
                    <Route path='/live/ship/engineStatus' render={() => (<EngineStatus
                        handleOpenPanel={handleOpenPanel}
                        handleSelectDG={handleSelectDG}
                        openedPanel={openedPanel}
                        isMapEnabled={isMapEnabled}
                        fetchEngineStatus={fetchEngineStatus}
                        engineStatus={engineStatus}
                        isLoading={isLoadingEngine}
                        severity={alert ? alert.Severity : '0'}
                        description={alertDetails ? alertDetails.description : 'The Alert has been closed!'}
                        subType={alertDetails ? alertDetails.subType : alertSubType}
                        openDetailAlert={this.state.openDetailAlert}
                        />)}
                        
                    />
                    <Route path='/live/ship/engineDetails' render={() => (<EngineDetails dgDetail={dgDetail} labelLoad={labelLoad} />)}></Route>
                    <Route path='/live/ship/itinerary' render={() => (<Itinerary
                        handleOpenPanel={handleOpenPanel}
                        openedPanel={openedPanel}
                        fetchVoyageInfo={fetchVoyageInfo}
                        voyageInfo={voyageInfo}
                        isLoading={isLoadingItinerary}
                        isMapEnabled={isMapEnabled}
                        fetchShipImage={fetchShipImage}
                        shipImage={shipImage} />)} />
                </div>
                <Footer enableAlerts={data.alerts && data.alerts.length > 0} selectedSection={selectedSection} openDetailAlert={this.state.openDetailAlert} handleSelectSection={handleSelectSection} />
            </React.Fragment>
        );

    }

}

export default LiveShip;