import React from 'react';
import EngineBox from './EngineBox';

function DieselGenerators(props){

    if(!props.DGList)
        return null;

    const dgsList = props.DGList.map((dg, id)=>{
        return <EngineBox labelLoad="DG Load" dg={dg} key={id} handleSelectDG={props.handleSelectDG}/>
    });

    return (
        <div className="engineContent">
            <span className="engineTitle">Diesel Generators</span>
            <span className="engineDate">Last Update - Engine Flow: {props.EngineLastUpdateUTCAsText}</span>
            <div style={{clear: 'both'}}></div>
            {dgsList}
            <div style={{clear: 'both'}}></div>
        </div>
    );
}

export default DieselGenerators;