import React from 'react';
import { withRouter } from "react-router-dom";
import './EngineBox.css';
import DgStatus from './DgStatus';

function EngineBox(props){

    const dg = props.dg;
    const statusColor = dg.AAQS ? dg.AAQS.StatusColor : '';

    function onButtonClick(){
        props.handleSelectDG(dg.Name, props.labelLoad);
        let { history } = props;
        history.push({
         pathname: '/live/ship/engineDetails/' + window.qstring
        });
       }

    return (
        <div className="engineBox">
            <div className={"header-status " + statusColor}>
                {dg.AAQS ? "AAQS: " + dg.AAQS.Status : ""}
            </div>
                {/* <Link to={`/engineDetails/${props.dg.Name}/${window.qstring}`} > */}
                <div className="content-table" style={{cursor: "pointer"}} onClick={onButtonClick}>
                    <DgStatus
                        DGStatusColor={dg.DGStatusColor}
                        Name={dg.Name}
                        DGLoad={dg.DGLoad}
                        FuelType={dg.FuelType}
                        labelLoad={props.labelLoad}
                    ></DgStatus>
                </div>
            {/* </Link> */}
        </div>
    );
}

export default withRouter(EngineBox);