import React, { Component } from 'react';
import './Settings.css';
import { ToastContainer, toast } from 'react-toastify';
import './toastify.min.css';
/*
import { style } from 'belle';
import { config } from 'belle';
*/
/*import { extend } from 'underscore';*/
import './nepmobilefont.css';
import './nepfont.css';
import './nauticalset.css';
import './components/ToggleSwitch.scss';
import ToggleSwitch from './components/ToggleSwitch';
import { Container, Label } from 'reactstrap';
import loading from './images/loading.svg';
//import viewSettings from './settings_items';
import Unauthorize from './Unauthorize';
import { useAccordionButton, Accordion, Card } from 'react-bootstrap';
import update from 'immutability-helper';

// const toRender_AAQS_C = `
// 	<div id="AAQS_C"></div>
// `;
// const toRender_AAQS_U = `
// 	<div id="AAQS_U"></div>
// `;
// const toRender_AAQS_A = `
// 	<div id="AAQS_A"></div>
// `;
const toRender_NEW_UR = `
	<div id="NEW_UR"></div>
`;


class Dynamic extends React.Component {
    markup(val) {
        return { __html: val }
    }

    render() {
        return <div dangerouslySetInnerHTML={this.markup(this.props.html)} />;
    }

    /*
    componentDidMount() {
        const node = document.querySelectorAll('#AAQS_C')[0];
        setTimeout(() => {
            node.innerHTML = `<span style="color:red;">This has been changed</span>`;
            this.props.updated();
        }, 2000);
    }
    */
}


class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            shipId: '',
            alertId: '',
            alertExternal: '',
            token: '',
            user: '',
            data: {},
            selectedSection: 'alertDetail',
            isLoadingSettings: true,
            isLoadingEngine: true,
            isFirstLoading: true,
            isUserAuthorized: true,
            // isChanged_C: false,
            // isChanged_U: false,
            // isChanged_A: false,
            isChanged_N: false,
            alertSelected: 0,
            groupsBrand: {
                // AAQS_COMPLIANCE_ALL: false,
                // AAQS_UNEXPECTED_ALL: false,
                // AAQS_APPROACHING_ALL: false,
                NEW_UR_ALL: false
            }
        };


        // Alarms Type
        // this.C_AAQS_COMPLIANCE = "AAQS_COMPLIANCE";
        // this.C_AAQS_UNEXPECTED = "AAQS_UNEXPECTED";
        // this.C_AAQS_APPROACHING = "AAQS_APPROACHING";
        this.C_NEW_UR = "NEW_USAGE_RATE";


        this.fetchSettings = this.fetchSettingsLoad.bind(this);
        this.handleChange = this.handleChangeToggle.bind(this);
        this.handleSelectionAllBrandsOf = this.handleChangeGroupSelectionToggle.bind(this);
        this.logState = this.logStateState.bind(this);
        this.saveSetting = this.saveSettingBrand.bind(this);
        this.saveGroupSetting = this.saveGroupSettingBrand.bind(this);

        // only for loading iniziale
        // this.updated_C = this.updated_C.bind(this);
        // this.updated_U = this.updated_U.bind(this);
        // this.updated_A = this.updated_A.bind(this);
        this.updated_N = this.updated_N.bind(this); 
        this.updateAll = this.updateAll.bind(this); 

        this.renderBrands = this.renderBrands.bind(this);
        //this.handleSelectSection = this.handleSelectSection.bind(this);

        this.baseUrl = 'https://' + (window.location.host.indexOf('localhost') !== -1 ? 'localhost:44435' : window.location.host);
        this.urlApi = this.baseUrl + '/api/NotificationCenter';
        this.proxyUrl = '/api/Proxy/Wms';

    }

    // updated_C() { this.setState({ isChanged_C: true }); }
    // updated_U() { this.setState({ isChanged_U: true }); }
    // updated_A() { this.setState({ isChanged_A: true }); }
    updated_N() { this.setState({ isChanged_N: true }); }

    updateAll() {

        // on init previous update
        //document.querySelectorAll('#AAQS_COMPLIANCE_ALL')[0].checked = false;
        //this.setState({ groupsBrand : { AAQS_COMPLIANCE_ALL: false } });
        //this.state.groupsBrand.AAQS_COMPLIANCE_ALL = false;


        // this.updated_C();
        // this.updated_U();
        // this.updated_A();
        this.updated_N();

        const loader = document.querySelectorAll('#loadercc')[0];
        if (loader) {
            loader.style.visibility = 'hidden';
        }
    }

    logStateState = () => {
        console.log(`${this.state.msgopen ? 'open' : 'closed'}`)
    }

    getTypeAlarms(gbnm) {
        var alarms;
        // if (gbnm == this.C_AAQS_COMPLIANCE) {
        //     alarms = this.state.data.Data.AlarmLive.AaqsCompliance;
        // } else if (gbnm == this.C_AAQS_UNEXPECTED) {
        //     alarms = this.state.data.Data.AlarmLive.AaqsUnexpected;
        // } else if (gbnm == this.C_AAQS_APPROACHING) {
        //     alarms = this.state.data.Data.AlarmLive.AaqsApproachingArea;
        // } else 
        
        if (gbnm == this.C_NEW_UR) {
            alarms = this.state.data.Data.AlarmMyShip.NewUr;
        } else {
            throw "!Event Not Valid!"
        }
        return alarms;
    }

    getCatName(NameCat) {
        // if (NameCat == this.C_AAQS_COMPLIANCE) {
        //     return "AaqsCompliance";
        // } else if (NameCat == this.C_AAQS_UNEXPECTED) {
        //     return "AaqsUnexpected";
        // } else if (NameCat == this.C_AAQS_APPROACHING) {
        //     return "AaqsApproachingArea";
        // } else 
        
        if (NameCat == this.C_NEW_UR) {
            return "NewUr";
        } else {
            throw "!Event Not Valid!"
        }
    }

    getItemsChecked = function (cat,catname) {

        if (this.state.data.Data == undefined)
            return false;

        var alarms = this.getTypeAlarms(catname);
        var _catname = this.getCatName(catname);
        var _tc = "AlarmLive";
        if (cat == "N")
            _tc = "AlarmMyShip";

        for (var i = 0; i < alarms.length; i++) {
            //console.log(this.state.data.Data.AlarmLive.AaqsCompliance[i]);

            if (this.state.data.Data[_tc][_catname][i].Enabled)
                return true;
        };
        return false;
    };

    updateChecksState = function (cat,catname, status) {

        if (this.state.data.Data == undefined)
            return;

        var alarms = this.getTypeAlarms(catname);
        var _catname = this.getCatName(catname);
        var _tc = "AlarmLive";
        if (cat == "N")
            _tc = "AlarmMyShip";


        for (var i = 0; i < alarms.length; i++) {
            //console.log(this.state.data.Data.AlarmLive.AaqsCompliance[i]);
            this.setState(state => (this.state.data.Data[_tc][_catname][i].Enabled = status, state));
        };
    };

    updateBrandChecksState = function (cat,catname, brandname, status) {

        if (this.state.data.Data == undefined)
            return;

        var alarms = this.getTypeAlarms(catname);
        var _catname = this.getCatName(catname);
        var _tc = "AlarmLive";
        if (cat == "N")
            _tc = "AlarmMyShip";

        for (var i = 0; i < alarms.length; i++) {
            if (alarms[i].Label == brandname.Label) {
                this.setState(state => (this.state.data.Data[_tc][_catname][i] .Enabled = !status, state));
            }
            /*
            if (cat == "N") {
                if (this.state.data.Data.AlarmMyShip[catname][i].Label == brandname.Label) {
                    console.log(this.state.data.Data.AlarmMyShip[catname][i]);
                    this.setState(state => (this.state.data.Data.AlarmMyShip[catname][i].Enabled = !status, state));
                }
            } else {
                if (this.state.data.Data.AlarmLive[catname][i].Label == brandname.Label) {
                    console.log(this.state.data.Data.AlarmLive[catname][i]);
                    this.setState(state => (this.state.data.Data.AlarmLive[catname][i].Enabled = !status, state));
                }
            }
            */
        };
    };
    

    componentDidMount() {

        //const AAQS_C = document.querySelectorAll('#AAQS_C')[0];
        //const AAQS_U = document.querySelectorAll('#AAQS_U')[0];
        //const AAQS_A = document.querySelectorAll('#AAQS_A')[0];
        //const NEW_UR = document.querySelectorAll('#NEW_UR')[0];

        window.qstring = window.location.search;
        const qs = new URLSearchParams(window.location.search);
        let alerting = "";
        if (qs.has("alertExternal"))
            alerting = qs.get("alertExternal");

        this.setState({ alertExternal: alerting, token: qs.get("token") }, this.fetchSettingsLoad);

        setTimeout(() => {
            /*
            AAQS_C.innerHTML = `<span style="color:red;">Loading...</span>`;
            AAQS_U.innerHTML = `<span style="color:red;">Loading...</span>`;
            AAQS_A.innerHTML = `<span style="color:red;">Loading...</span>`;
            NEW_UR.innerHTML = `<span style="color:red;">Loading...</span>`;
            */
            this.updateAll();
        }, 1000);

    }

    fetchSettingsLoad() {

        const { token } = this.state;
        const url = this.urlApi + `/LoadSettings/?token=${token}`;

        this.setState({ isLoadingSettings: true });
        this.setState({ isLoadingEngine: true });

        fetch(url, {
            //credentials: 'include',
            method: 'get'
        })
            .then(response => {
                if (!response.ok)
                    throw Error(response.statusText);

                return response.json();
            })
            .then(rdata => {
                this.setState({ settingsInfo: rdata.settings, isLoadingSettings: true, LoadingEngine: false,  data: rdata });
                this.updateAll();
            })
            .catch(err => {
                let user = "";

                if (err.message.indexOf("Unauthorized:") !== -1)
                    user = err.message.substring(err.message.indexOf(":") + 1);

                this.setState({ isUserAuthorized: false, isLoadingSettings: false, LoadingEngine: false,  user });
                console.log(err);
            });
    }

    saveGroupSettingBrand(TypeGroup, GroupBrandName, StatusGroupOnOrOff) {

        const { token } = this.state;
        var self = this;
        var gbnm = GroupBrandName;

        const url = this.urlApi + `/SaveSettingsGroupBrand`;

        const loader = document.querySelectorAll('#loadercc')[0];
        if (loader) {
            loader.style.visibility = 'visible';
        }
        //           string token,string Cat,string groupname or undeinfed, bool statusOnOrOff
        let data = { token: token, cat: TypeGroup, catname: GroupBrandName, notifiquestatus: StatusGroupOnOrOff };
        var fdata = new FormData();
        for (var p in data) { fdata.append(p, data[p]) }
        var statustochange = StatusGroupOnOrOff;

        fetch(
            url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json; charset=utf-8',
                //'Content-Type': 'multipart/form-data'
            },
            body: fdata,
            //credentials: 'include',
        })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(rdata => {
                self.updateChecksState(TypeGroup,gbnm, statustochange);
            })
            .then(status => {
                loader.style.visibility = 'hidden';
            })
            .catch(err => {
                loader.style.visibility = 'hidden';
                self.setState(state => (state.groupsBrand[GroupBrandName] = !state.groupsBrand[GroupBrandName], state))
                toast("Error..: " + err.message + ", undo operation!!");
                console.log(err.message);
            });

    }

    saveSettingBrand(Ctrl,Cat,CatName,Brand) {

        const { token } = this.state;
        var self = this;

        const url = this.urlApi + `/SaveSettingsSingleBrand`;

        const loader = document.querySelectorAll('#loadercc')[0];
        if (loader) {
            loader.style.visibility = 'visible';
        }
        //           string token,string cat, string brand      , bool userstatus
        let data = { token: token, cat: Cat, brand: Brand.Label, notifiquestatus: Ctrl.checked };
        var fdata = new FormData();
        for (var p in data) { fdata.append(p, data[p]) }
        fetch(
            url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json; charset=utf-8',
                    //'Content-Type': 'multipart/form-data'
                },
                body: fdata,
                //credentials: 'include',
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }

                return response.json();
            })
            .then(rdata => {
                self.updateBrandChecksState(Cat, CatName,Brand, Ctrl.checked);
            })
            .then(status => {
                loader.style.visibility = 'hidden';
            })
            .catch(err => {
                loader.style.visibility = 'hidden';
                Ctrl.checked = !Ctrl.checked;
                toast("Error..: " + err.message + ", undo operation!!");
                console.log(err.message);
            });
    }

    handleChangeToggle(ctrl, cat,catname, brand) {
        
        this.saveSetting(ctrl, cat,catname, brand);
        //console.log("Checkbox Current State: " + e + " - " + cat + " " + Brand.Label);
    }

    handleChangeGroupSelectionToggle(cat,catname, AllOnOrOff) {
        //handleChangeGroupSelectionToggle
        //this.setState(state => (state.groupsBrand[catname] = !AllOnOrOff, state))
        this.saveGroupSetting(cat,catname, AllOnOrOff);
        //console.log("Checkbox Current State: " + e + " - " + cat + " " + Brand.Label);
    }

    setAlertSelected = event => {
        this.setState({alertSelected: (this.state.alertSelected === +event ? -1 : +event)});
    }

    renderBrands(typeAlarmBrand) {
        
        if (this.state.data ) {
            var BrandsNames = this.state.data.Data;
            if (BrandsNames) {

                function retm(s, n) {
                    //console.log("item:", s + n.Label.replace(' ', '_'))
                    return s + n.Label.replace(' ','_');
                }
                function itemTemplate(brand, cat,catname,self) {
                    if (brand.Usable) {
                        return <div className="tcontainer" id={retm("l_" + cat, brand)} >
                            <div className="trow">
                                <div className="tleft"><h4>&nbsp;</h4></div>
                                <div className="tmiddle"><h4>{brand.Label}</h4></div>
                                <div className="tright">
                                    <ToggleSwitch id={retm("s_" + cat, brand)}
                                        onChange={(e) => self.handleChange(e, cat, catname, brand)} Text={["ON", "OFF"]}
                                        disabled={!brand.Usable}
                                        defaultChecked={brand.Enabled}
                                        currentValue={brand.Enabled}
                                        />
                                </div>
                            </div>
                        </div >
                    } else {
                        return <div className="tcontainer"><div className="trow">----</div></div>;
                    }
                }
                // if (typeAlarmBrand == 'C') {
                //     return BrandsNames.AlarmLive.AaqsCompliance.map(brand => (itemTemplate(brand, typeAlarmBrand, this.C_AAQS_COMPLIANCE ,this))); 
                // } else if (typeAlarmBrand == 'U') {
                //     return BrandsNames.AlarmLive.AaqsUnexpected.map(brand => (itemTemplate(brand, typeAlarmBrand, this.C_AAQS_UNEXPECTED, this)));
                // } else if (typeAlarmBrand == 'A') {
                //     return BrandsNames.AlarmLive.AaqsApproachingArea.map(brand => (itemTemplate(brand, typeAlarmBrand, this.C_AAQS_APPROACHING, this)));
                // } else 
                
                if (typeAlarmBrand == 'N') {
                    return BrandsNames.AlarmMyShip.NewUr.map(brand => (itemTemplate(brand, typeAlarmBrand, this.C_NEW_UR, this)));
                };
                return <div className="tcontainer"><div className="trow">brands not found!!</div></div>;

            } else {
                return <div>loading...</div>
            }
        }
        else {
            return <div className="tcontainer"><div className="trow">brands not loaded!!</div></div>;
        }
    }

    render() {

        var self = this;

        function CustomToggle({ children, eventKey, setAlertSelected, alertSelected, SigleGroup, NameGroup, StateGroup }) {

            if (NameGroup == undefined)
                NameGroup = "";

            const handleSelection = function (e, Cat, CatName) {
                self.handleSelectionAllBrandsOf(Cat, CatName, e.checked);
            }
            const decoratedOnClick = useAccordionButton(eventKey, () =>
                setAlertSelected(eventKey)
            );
            const arrow = alertSelected === +eventKey ? 'icon-down' : 'icon-next';

            return (
                <div>
                    <label
                        type="label"
                        style={{ backgroundColor: 'transparent', float: 'left', width: '100%', textAlign: 'left', marginBottom: 0.0 }}
                        onClick={decoratedOnClick} >
                        <i style={{ float: 'left' }} className={arrow}></i>
                        {children}
                        <div style={{ float: 'left' }} className="tleft">{NameGroup.replace('_', ' ').replace(' ', '_').replace(' ', '_') }</div>
                    </label>
                    <div style={{ float: 'right' }} className="groupBrand">
                        <ToggleSwitch id={NameGroup}
                            onChange={(e) => handleSelection(e, SigleGroup, NameGroup)}
                            Text={["ON", "OFF"]} disabled={false}
                            defaultChecked={self.getItemsChecked(SigleGroup,NameGroup)}  />
                    </div>
                </div>
            );
        }


        var loadingContent;
        if (this.state.isFirstLoading)
            loadingContent = <div id="loadercc" className="loadingbg"><img src={loading} alt="loading" /></div>;

        if (!this.state.isUserAuthorized)
            return (<Unauthorize user={this.state.user} />);

        return (
            <div className="Settings">
                {/* <Header shipName={this.state.data.shipName} logo={logo} /> */}


                <div id="contentBody">
                    <div className="TitleBack">
                        <div className="sTitleBack">
                            <span className="settingsTitle">Settings</span>
                        </div>
                    </div>
                    {loadingContent}
                    <ToastContainer />
                    <Accordion className="contentSettings" defaultActiveKey="0">

                        {/* <div className="titles">
                            <img src="../img/logo_alarmLive.png" alt="Logo " className="img-fluid iconTitle" />
                            <Label className="titleAlarm">LIVE</Label>
                        </div>

                        <Card className="CardsElements">
                            <Card.Header className="TitleBrand">
                                <CustomToggle className="ToggleBrand" eventKey="0"
                                    setAlertSelected={this.setAlertSelected} alertSelected={this.state.alertSelected}
                                    SigleGroup="C" NameGroup={this.C_AAQS_COMPLIANCE}
                                    StateGroup={this.state.groupsBrand.AAQS_COMPLIANCE_ALL}
                                />
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Container fluid className="setItems">

                                        <div>{this.state.isChanged_C ? this.renderBrands('C') : `loading...`}</div>
                                        <Dynamic html={toRender_AAQS_C} updated_C={this.updated_C} />

                                    </Container>

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="CardsElements">
                            <Card.Header className="TitleBrand">
                                <CustomToggle className="ToggleBrand" eventKey="1"
                                    setAlertSelected={this.setAlertSelected} alertSelected={this.state.alertSelected}
                                    SigleGroup="U" NameGroup={this.C_AAQS_UNEXPECTED}
                                    StateGroup={this.state.groupsBrand.AAQS_UNEXPECTED_ALL}
                                    />
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>

                                    <Container fluid className="setItems">

                                        <div>
                                            <div>{this.state.isChanged_U ? this.renderBrands('U') : `loading...`}</div>
                                            <Dynamic html={toRender_AAQS_U} updated_U={this.updated_U} />
                                        </div>

                                    </Container>

                                </Card.Body>

                            </Accordion.Collapse>
                        </Card>
                        <Card className="CardsElements">
                            <Card.Header className="TitleBrand">
                                <CustomToggle className="ToggleBrand" eventKey="2"
                                    setAlertSelected={this.setAlertSelected} alertSelected={this.state.alertSelected}
                                    SigleGroup="A" NameGroup={this.C_AAQS_APPROACHING}
                                    StateGroup={this.state.groupsBrand.AAQS_APPROACHING_ALL}
                                    />
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>

                                    <Container fluid className="setItems">

                                        <div>
                                            <div>{this.state.isChanged_A ? this.renderBrands('A') : `loading...`}</div>
                                            <Dynamic html={toRender_AAQS_A} updated_A={this.updated_A} />
                                        </div>

                                    </Container>

                                </Card.Body>

                            </Accordion.Collapse>
                        </Card> */}

                        <div className="titles">
                            <img src="../img/logo_alarmMyShip.png" alt="Logo" className="img-fluid iconTitle" />
                            <Label className="titleAlarmMyShip" > MYSHIP </Label>
                        </div>

                        <Card className="CardsElements">
                            <Card.Header className="TitleBrand">
                                <CustomToggle className="ToggleBrand" eventKey="3"
                                    setAlertSelected={this.setAlertSelected} alertSelected={this.state.alertSelected}
                                    SigleGroup="N" NameGroup={this.C_NEW_UR}
                                    StateGroup={this.state.groupsBrand.NEW_UR_ALL}
                                    />
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>

                                    <Container fluid className="setItems">

                                        <div>
                                            <div>{this.state.isChanged_N ? this.renderBrands('N') : `loading...`}</div>
                                            <Dynamic html={toRender_NEW_UR} updated_N={this.updated_N} />
                                        </div>

                                    </Container>


                                </Card.Body>

                            </Accordion.Collapse>
                        </Card>

                    </Accordion>


                </div>
                {/* <Footer selectedSection={this.state.selectedSection} handleSelectSection={this.handleSelectSection} /> */}
            </div>
        );
    }

}

export { Settings };