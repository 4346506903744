import React from 'react';

function AlertHeader(props){

    return <div>
                <div className="alertIcon"><div className="iconbg"><div className="egcsIcon"><i className={"icon-timone sev" + props.severity}><i className="icon-egcs"></i></i></div></div></div>
                <div className="alertDesc">
                <div className="alertTitle">{props.description}</div>
                <div className="alertSubTitle">{props.subType}</div>
                </div>
            </div>

}

export default AlertHeader;