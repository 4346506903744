import React from 'react';
import AlertHeader from './AlertHeader';
import DieselGenerators from './DieselGenerators';
import GasTurbines from './GasTurbines';
import AshoreConnection from './AshoreConnection';
import loading from './images/loading.svg';

class EngineStatus extends React.Component {

  componentDidMount(){
    this.props.fetchEngineStatus();
  }

  render(){
    const engineStatus = this.props.engineStatus;
    var content;

    if(!engineStatus.DGList && this.props.isLoading)
        content =<div className="loading"><img src={loading} alt="loading" /></div>
    else{
      let gasTurbines = engineStatus.GTList && engineStatus.GTList.length > 0 ? <GasTurbines GTList={engineStatus.GTList} handleSelectDG={this.props.handleSelectDG} /> : null;

      content = <React.Fragment>
                <DieselGenerators DGList={engineStatus.DGList} EngineLastUpdateUTCAsText={engineStatus.EngineLastUpdateUTCAsText} handleSelectDG={this.props.handleSelectDG} />
                {gasTurbines}
                <AshoreConnection AshoreConnection={engineStatus.AshoreConnection} EnergyLastUpdateUTCAsText={engineStatus.EnergyLastUpdateUTCAsText} />
              </React.Fragment>
    }


    return (
        <div className={this.props.openedPanel == "engineStatus" || !this.props.isMapEnabled ? "slidePanel open" : "slidePanel small"}>
            <div className={"header headersmall"} onClick={()=>this.props.handleOpenPanel('engineStatus')}>
            <div className={!this.props.isMapEnabled ? "slideButton hide" : "slideButton " + (this.props.openedPanel=="engineStatus" ? "icon-down" : "icon-up")}></div>
                {/*{this.props.openDetailAlert ? <AlertHeader severity={this.props.severity} description={this.props.description} subType={this.props.subType} /> : null}*/}
          </div>
          <div className="content">
            <div className="title">Engine Status</div>
            {content}
        </div>
        <div className="panelfooter"></div>
      </div>
    );
  }
}

export default EngineStatus;