import React from 'react';
import './Itinerary.css';
import shipImage from './images/image-not-available.jpg';
import CurrentRoute from './CurrentRoute';
import NauticalInfo from './NauticalInfo';
import loading from './images/loading.svg';

class Itinerary extends React.Component {

    componentDidMount(){

        if(!this.props.shipImage)
            this.props.fetchShipImage();

        this.props.fetchVoyageInfo();
    }

    GetNavigationStatusIcon(status){
        let icon = "";
        switch(status){
            case "In port":
                icon = "ns-icon-07";
                break;
            case "At anchor":
                icon = "ns-icon-06";
                break;
            case "At sea":
                icon = "ns-icon-05";
                break;
            default:
                icon = "nepfont_question";
        }

        return icon;
    }

    render(){
        const shipVoyage = this.props.voyageInfo;
        var style;

        if(this.props.shipImage){
            style={  
                backgroundImage: "url(data:image;base64," + this.props.shipImage + ")",
                backgroundPosition: 'right center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
              };
        }else{
            style={  
                backgroundImage: "url(" + shipImage + ")",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
              };
        }

        var headerContent;

        if(!shipVoyage.NavigationStatusName && this.props.isLoading)
            headerContent =<div className="loading"><img src={loading} alt="loading" /></div>
        else
            headerContent = <React.Fragment>
                <div className={!this.props.isMapEnabled ? "slideButton " : "slideButton " + (this.props.openedPanel=="itinerary" ? "icon-down" : "icon-up")}></div>
                    <div className="shipStatusContent">
                        <div className="navStatus"><i className={this.GetNavigationStatusIcon(shipVoyage.NavigationStatusName)} ></i>{shipVoyage.NavigationStatusName}</div>
                        <div className="shipImage">
                            <img alt="" style={style} />
                        </div>
                    </div>
            </React.Fragment>

        return (
            <div className={this.props.openedPanel=="itinerary" || !this.props.isMapEnabled ? "slidePanel open" : "slidePanel"}>
                <div className="header" onClick={()=>this.props.handleOpenPanel('itinerary')}>
                    {headerContent}
                </div>
                <div className="content">
                    <div className="title">Itinerary</div>
                    <CurrentRoute current={shipVoyage.Current}/>
                    <NauticalInfo nautical={shipVoyage.Nautical}/>
                </div>
            </div>
        );        
    }

}

export default Itinerary;