import React from 'react';
import BoxRow from './BoxRow';

function DGEngine(props){
    
    const engine = props.DGEngineDetails;

    if(!engine)
        return null;

    return (
        <div className="box">
            <span className="boxTitle">Engine</span>
            <span className="engineDate">Last Update: {engine.LastUpdateUTCAsText}</span>
            <div style={{clear: "both"}}></div>
            <div className="boxContent">
                <div className="div-table">
                    <BoxRow title="Actual Power:" value={engine.ActualPower + " kW"} />
                    <BoxRow title="SFOC ISO:" value={engine.SFOCISO + " g/kWh"} />
                    <BoxRow title="SFOC:" value={engine.SFOC + " g/kWh"} />
                    <BoxRow title="Running hours:" value={engine.RunningHours + " Hours"} />
                    <BoxRow title="Fuel Consumption:" value={engine.FuelConsumption + " kg/h"} />
                </div>
            </div>
        </div>
    );
       
}

export default DGEngine;