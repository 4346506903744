import React from 'react';
import BoxRow from './BoxRow';

class InfoAlert extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        const alert = this.props.alertDetails;

        if(!alert)
            return null;

        return (
            <div className="box">
                <div className="boxTitle">Info {alert.alarmAlertStr}</div>
                <div className="boxContent">
                    <div className="div-table">
                        <BoxRow title="Type:" value={alert.description} />
                        <BoxRow title="SubType:" value={alert.subType} />
                        <BoxRow title="Date:" value={alert.formattedAlertDate} />
                        <BoxRow title="GPS position:" value={alert.position} />
                        {/* <BoxRow title="Earlier Heading:" value={alert.heading} /> */}
                        <BoxRow title="Sailing Areas:" value={alert.activeAreas} />
                    </div>
                </div>
            </div>
        );
    }
        
}

export default InfoAlert;