import React from 'react';
import { withRouter } from "react-router-dom";
import DgStatus from './DgStatus';
import DGEngine from './DGEngine';
import DGAAQS from './DGAAQS';
import GTEngine from './GTEngine';
import './EngineDetails.css';

class EngineDetails extends React.Component{
    constructor(props){
        super(props);

        this.goBack =this.goBack.bind(this);
    }
    goBack(){
        this.props.history.goBack();
    }

   
    render(){
        console.log(this.props);
        //const { dgFromLink, labelLoad } = this.props.location.state

        if(!this.props.dgDetail)
            return null;

        const dg = this.props.dgDetail;
        const labelLoad = this.props.labelLoad;
        const statusColor = dg.AAQS ? dg.AAQS.StatusColor : '';

        return (
            <div className="slidePanel open">
                <div className="header engineDetails">
                    <div>
                        <span className="btnClose" onClick={()=>{this.goBack();}}><i className="icon-close1"></i></span>
                        <div style={{clear: 'both'}}></div>
                    </div>
                    <div className="content-table">
                        <DgStatus
                            DGStatusColor={dg.DGStatusColor}
                            Name={dg.Name}
                            DGLoad={dg.DGLoad}
                            FuelType={dg.FuelType}
                            labelLoad={labelLoad}
                        ></DgStatus>
                        <div className="col3">
                            {dg.AAQS ? <div className={"aaqsStatus " + "border-" + statusColor}> {"AAQS: " + dg.AAQS.Status}</div> : ""}
                        </div>
                    </div>
                </div>
                <div className="content">
                    <GTEngine DGEngineDetails={dg.EngineGT}/>
                    <DGEngine DGEngineDetails={dg.Engine} />
                    <DGAAQS DGAAQSDetails={dg.AAQS}/>
                </div>
              <div className="panelfooter"></div>
            </div>
          );
    }
}

export default withRouter(EngineDetails);