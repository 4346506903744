import React, { Component } from 'react';
import './Support.scss';
import mail from './images/mail.svg';
//import MobileButton from './components/MobileButton';

class Support extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null
        }
    }
    componentDidMount() {

        this.props.fetchSupport().then(data => {
            if (data.emailSupport)
                this.setState({ email: data.emailSupport });
        });

    }

    postMessage = message => {
        if (window.ReactNativeWebView)
            window.ReactNativeWebView.postMessage(message);
    }

    render() {

        if (!this.state.email)
            return null;

        const email = this.state.email;

        return (
            <div className="pageContent" id="support">
                <div className="content">
                    <div className="title">Neptune support</div>
                    <div className="questiontext">HOW TO OPEN A TICKET?</div>
                    <div className="text"><p>Send an email to</p> 
                    <a href={"mailto:" + email}>
                        <div className="mailsupport"><img alt="mail" src={mail} /> {email}</div>
                    </a>
                    <p>providing a <strong>screenshot</strong> and a <strong>description</strong> of the issue.</p></div>
                    {/*<MobileButton className="logoutbtn" onClick={() => this.postMessage('logout')}><i className="icon-esc"></i>Logout</MobileButton>*/}
                </div>
            </div>
        );
    }
}

export default Support;