import React from 'react';

function InitialCauseAlarm(props){

    const tableRows = props.initialCause.map((row, id)=>{
        return <div className="div-table-row" key={id}>
                    <div className="div-table-cell">{row.dg}</div>
                    <div className="div-table-cell"><span>{row.name}</span></div>
                </div>
    });

        return (
            <div className="box">
                <div className="boxTitle">Initial cause of the {props.alarmAlertStr}</div>
                <div className="boxContentAlert">
                    <div className="div-table">
                        <div className="div-table-row-header">
                            <div className="div-table-cell">DG\Ship</div>
                            <div className="div-table-cell"><span>Description</span></div>
                        </div>
                        {tableRows}
                    </div>
                </div>
            </div>
        );
}

export default InitialCauseAlarm;