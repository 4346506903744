import React from 'react';

function BoxRow(props) {

    let thresholds = null;
    let marineTraffic = null;

    if(props.thresholds)
        thresholds = <span className="thresholds">{props.thresholds}</span>

    if (props.marineTrafficSource) {
        marineTraffic = <span class="MTSource">
            <i class="nepfont_marine-traffic"></i>MARINE TRAFFIC
        </span>
    }

    return (
            <div className="div-table-row">
                <div className="div-table-col left">{props.title}</div>
            <div className="div-table-col p60"><span>{props.value} {props.children}</span>{thresholds}{marineTraffic}</div>
            </div>
    );
  }
  
  export default BoxRow;