import React from 'react';
import BoxRow from './BoxRow';

class CurrentRoute extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        const current = this.props.current;

        if(!current)
            return null;

        return (
            <div className="box">
                <div className="boxTitle">Current Route</div>
                <div className="boxContent">
                    <div className="div-table">
                        <BoxRow title="Departure port:" value={current.DeparturePortName} />
                        <BoxRow title="Arrival port:" value={current.ArrivalPortName} />
                        <BoxRow title={(current.NavigationStatusCode == 0 || current.NavigationStatusCode == 15) ? "Arrival time: " : "Departure time: "} value={current.DepartureOrArrivalTime} />
                        <BoxRow title="Time on Board:" value={current.TimeOnBoard} />
                        <BoxRow title="Sailing Areas:" value={current.ActiveAreas} />
                    </div>
                </div>
            </div>
        );
    }
        
}

export default CurrentRoute;