import React from 'react';
import './Steps.css';

function Steps(props){

    const historyLogs = props.historyLogs;

    if(!historyLogs)
        return null;

    const steps = historyLogs.map((s, id)=>{
        return <div className={"step " + (id===0 ? "selected" : "")} key={id}>
                    <div className="step-header">
                        <div className="headerText"><span>{s.source}</span><span className="right">{s.formattedLogDate}</span></div>
                        <div className="subheader">Status: {s.status}</div>
                        <div className="subheader">{s.actionDescription}</div>
                    </div>
                </div>
    });
    return (
        <div className="steps">
            {steps}
        </div>
    );
}

export default Steps;