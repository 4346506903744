import React from 'react';
import BoxRow from './BoxRow';
import UpdateIssue from './components/UpdateIssue';

class NauticalInfo extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        const nautical = this.props.nautical;

        if(!nautical)
            return null;

        return (
            <div className="box">
                <div style={{display:'flex'}}>
                    <div className="boxTitle">Nautical Info</div>
                    {nautical.UpdateIssue ? <UpdateIssue /> : null}
                </div>
                <div className="boxContent">
                    <div className="div-table">
                        <BoxRow title="Latitude:" value={nautical.Latitude.value} marineTrafficSource={nautical.Latitude.marineTrafficSource} />
                        <BoxRow title="Longitude:" value={nautical.Longitude.value} marineTrafficSource={nautical.Longitude.marineTrafficSource} />
                        <BoxRow title="SOG:" value={nautical.SOG.value} marineTrafficSource={nautical.SOG.marineTrafficSource} />
                        <BoxRow title="STW:" value={nautical.STW.value} marineTrafficSource={nautical.STW.marineTrafficSource} />
                        <BoxRow title="Heading:" value={nautical.Heading.value} marineTrafficSource={nautical.Heading.marineTrafficSource} />
                        <BoxRow title="Course:" value={nautical.Course.value} marineTrafficSource={nautical.Course.marineTrafficSource} />
                        <BoxRow title="ROT:" value={nautical.ROT.value} marineTrafficSource={nautical.ROT.marineTrafficSource} />
                        <BoxRow title="UKC:" value={nautical.UKC.value} marineTrafficSource={nautical.UKC.marineTrafficSource} />
                        <BoxRow title="Chart Min Depth:" value={nautical.ChartMinDepth ? nautical.ChartMinDepth.value : ""} />
                        <BoxRow title="Chart Max Depth:" value={nautical.ChartMaxDepth ? nautical.ChartMaxDepth.value : ""} />
                    </div>
                </div>
            </div>
        );
    }
        
}

export default NauticalInfo;