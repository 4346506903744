import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './AlertDetail.css';
import InfoAlert from './InfoAlert';
import InitialCauseAlarm from './InitialCauseAlarm';
import CurrentParameters from './CurrentParameters';
import HistoryLogAlert from './HistoryLogAlert';
import AlertHeader from './AlertHeader';
import loading from './images/loading.svg';

class AlertDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            alertDetails: null
        }
    }

    componentDidMount() {
        this.props.fetchAlertData().then(() => {
            this.setState({ isLoading: false }, () => this.props.handleSelectSection('alertDetail'));
        });

        window.qstring = window.location.search;

        
        //this.props.handleInitLoading();
        this.props.setOpenDetailAlert(true);

    }

    goToAlerts = () => {
        const qs = new URLSearchParams(window.location.search);
        const shipId = qs.get("shipId");
        const token = qs.get("token");

        this.props.setOpenDetailAlert(false);
        this.props.handleSelectSection('alerts');
        this.props.history.push(`/live/ship/alerts/?shipId=${shipId}&token=${token}`);
    }

    render() {

        const { openedPanel, isMapEnabled, alertSubType, handleOpenPanel, alertDetails, alert } = this.props;

        const iconButton = openedPanel === "alertDetail" ? "icon-down" : "icon-up";

        if(!alertDetails || !alert){

            var headerAlert = null;
          

            if (!this.props.isLoading) {
                if (!this.state.isLoading)
                    headerAlert = <AlertHeader severity="0" description="The Event has been closed!" subType={alertSubType} />;
                else
                    headerAlert = <div className="loading"><img src={loading} alt="loading" /></div>
            }

            return <div className={openedPanel==="alertDetail" || !isMapEnabled ? "slidePanel open" : "slidePanel"}>
                        <div className="header" onClick={()=>handleOpenPanel('alertDetail')}>
                        <div className={!isMapEnabled ? "slideButton hide" : "slideButton " + iconButton}></div>
                            {headerAlert}
                        </div>
                        <div className="content">
                            {!this.props.isLoading && <div className="alertContentMessage">Please go to lists for other Alarms/Alerts.</div>}
                        </div>
                    </div>
        }


/*   if(!props.alertDetails)
    return <AlertNotFound isLoading={props.isLoading} isMapEnabled={props.isMapEnabled}/> */
        //let alert = null;

        //if (this.state.alertId && this.props.alerts) {
        //    alert = this.props.alerts.filter(a => a.Id === this.state.alertId);
        //    if (alert.length > 0) {
        //        alert = alert[0];
        //    }

        //}

        const { alarmAlertStr } = alertDetails;

      return (
        <div className={openedPanel==="alertDetail" || !isMapEnabled ? "slidePanel open" : "slidePanel"}>
              <div className="header" onClick={() => handleOpenPanel('alertDetail')}>
                  <span className="alertDetailClose" onClick={this.goToAlerts}><i className="icon-close1"></i></span>
                  <div className={!isMapEnabled ? "slideButton hide" : "slideButton " + iconButton}></div>
                  <AlertHeader severity={alert.Severity} description={alert.Type} subType={alert.SubTypeName} />
            </div>
            <div className="content">
                <div className="title">{alarmAlertStr} Detail</div>
                <InfoAlert alertDetails={alertDetails} />
                <InitialCauseAlarm initialCause={alertDetails.initial} alarmAlertStr={alarmAlertStr} />
                <CurrentParameters currentParams={alertDetails.current} alarmAlertStr={alarmAlertStr} />
                <HistoryLogAlert historyLogs={alertDetails.historyLogs} />
            </div>
        </div>
            );
        }
}

export default withRouter(AlertDetail);