import React from 'react';

function Unauthorize({ isUserAuthorized, user, errorObj}){
    if (!errorObj || !errorObj.errorMessage)
        return null;

    let message = "";

    if (!isUserAuthorized && errorObj.errorMessage !== "InvalidToken")
        message = "You don’t have permission to see this brand ship";
    else if (!isUserAuthorized && errorObj.errorMessage === "InvalidToken")
        message = "Your token is invalid";
    else
        message = errorObj.errorMessage;

    return(
        <div id="usernotallowed">
            <div className="content">
                <div className="logo"></div>
                <div className="user">
                    <div>WELCOME</div>
                    {user.length > 0 && <div><i className="icon-user1"></i> {user}</div>}
                </div>
                <div>
                    <i className="attention icon-attention-message"></i>
                </div>
                <div className="message">
                    <span>{message}</span>
                </div>
                 
            </div>
        </div>
    );
}

export default Unauthorize;