import React from 'react';


class Map extends React.Component {
    constructor(props) {
        super(props);
        window.mapObj = null;
        const qs = new URLSearchParams(window.location.search);
        const alertId = qs.get("alertId");

        this.state = { isPanToMap: false, alertId};
    }
     componentDidMount(){
         let mapZoomOptions = {};

         if (this.props.data.mapZoomOptions) {
             mapZoomOptions = this.props.data.mapZoomOptions;
         }

         window.mapObj = new window.LiveMap({
            isOldMapServer: this.props.data.isOldMapServer || false,
            defaultZoomLevel: mapZoomOptions.Default || 8,
            minZoom: mapZoomOptions.Min || 3,
            maxZoom: mapZoomOptions.Max || 17,
            mapServerUrl: this.props.data.mapUrl,//'https://wmsserver.costa.it',
            mapServerName: "SevenCsWMS",
            elementId: "map",
            noLayersSwitcher: true,
            noMeteoSwitcher: true,
            noScaleBar: true,
            noWeatherBar: true,
            noECA: true,
            noMeasure: true,
            routeEditing: false,
            KTWMSMinZoom: 3,
            minZoomAis: 10,
            minZoomTargetRadar: 10,
            minZoomToWaypointsLabels: 8,
            hideWaypoints: true,
            minZoomToShipContour: 100,
            onLoadEnd: this.props.handleEnableMap
        });

        this.updateMap();
    } 

    componentDidUpdate(prevProps, prevState) {
        this.updateMap(prevProps, prevState);
    }

    getAlert = () => {
        const qs = new URLSearchParams(window.location.search);
        const alertId = qs.get("alertId");
        const alerts = this.props.data.alerts;
        if (alerts) {
            let alert = alerts.filter(a => a.Id === alertId);
            if (alert.length > 0) {
                return alert[0];
            }
        }
        return null;
    }

    updateMap = (prevProps, prevState)=>{
        const data = this.props.data;
        const alert = this.getAlert();

        const isUpdateActuals = !prevProps || prevProps.data.actuals !== data.actuals ? true : false;
        const isUpdateVppPolys = !prevProps || prevProps.data.vppPolys !== data.vppPolys ? true : false;
        const isUpdatesafeZones = !prevProps || prevProps.data.safeZones !== data.safeZones ? true : false;
        const isUpdateAlert = !prevState || !alert || !prevState.alertId || prevState.alertId !== alert.Id ? true : false;
        const isUpdateShip = !prevProps || prevProps.data.ship !== data.ship ? true : false;

        if (data.actuals && isUpdateActuals) {
            for (var actualId in data.actuals) {
                var actual = data.actuals[actualId];
                window.mapObj.updateActual(actualId, actual.wkt, actual.wktStyle);
            }
        }
        
        if (data.vppPolys && isUpdateVppPolys) {
            for (var vppPolyId in data.vppPolys) {
                var vppPoly = data.vppPolys[vppPolyId];
                window.mapObj.updateVPP(vppPolyId, vppPoly.wkt, vppPoly.wktStyle);
            }
        }

        if (data.safeZones && isUpdatesafeZones)
            for (var safeZoneId in data.safeZones) {
                var safeZone = data.safeZones[safeZoneId];
                window.mapObj.addSafeZone(safeZoneId, safeZone.wkt, safeZone.wktStyle);
            }

        if(data.ship && isUpdateShip)
            window.mapObj.updateShip(data.ship.Id, data.ship.Name, data.ship.Lon, data.ship.Lat, data.ship.BrandCode, data.ship.Speed, data.ship.Heading);
        
        if (alert && isUpdateAlert) {
            window.mapObj.removeAllAlerts();
            window.mapObj.addAlertEGCS(alert.Id, "alertselected", alert.Lon, alert.Lat, alert.Severity, false);
            this.setState({ isPanToMap: false, alertId: alert ? alert.Id : null });
        }

            

        if(!this.state.isPanToMap){

            let lat = 0, lon = 0;

            if(alert){
                lat = alert.Lat;
                lon = alert.Lon;
            }

            if(lat == 0 && lon == 0){
                lat = data.ship.Lat;
                lon = data.ship.Lon;
            }
            window.mapObj.panTo(lon, lat);

            this.setState({ isPanToMap: true});
        }
    };

    render(){
        return (
            <React.Fragment>
                <div id="map" className="map on"></div>
                <div id="viewToShipButton" className={!this.props.viewAlertButton ? 'hideAlertButton' : ''}></div>
            </React.Fragment>
            
          );
    }
}

export default Map;