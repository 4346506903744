import React from 'react';
import { Link } from "react-router-dom";
import './Footer.css';

function Footer({ selectedSection, handleSelectSection, openDetailAlert, enableAlerts }) {
    const alertButton = enableAlerts ? (!openDetailAlert && selectedSection !== 'alertDetail' ?
        <li className={selectedSection === 'alerts' ? 'selected' : ''} onClick={() => handleSelectSection('alerts')}>
            <Link to={`/live/ship/alerts/${window.qstring}`}>
                <div><i className="icon-alert-info"></i></div>
                <div>Alarms/Alerts</div>
            </Link>
        </li> :
        <li className={selectedSection === 'alertDetail' ? 'selected' : ''} onClick={() => handleSelectSection('alertDetail')}>
            <Link to={`/live/ship/alertDetail/${window.qstring}`}>
                <div><i className="icon-alert-info"></i></div>
                <div>Alarms/Alerts</div>
            </Link>
        </li>) : <li className="disabled" >
                <div><i className="icon-alert-info"></i></div>
                <div>Alarms/Alerts</div>
        </li>;
    
  return (
    <footer>
          <ul className="buttonbar">
              {alertButton}
            <li className={selectedSection==='engineStatus' ? 'selected' : ''} onClick={()=>handleSelectSection('engineStatus')}>
                  <Link to={`/live/ship/engineStatus/${window.qstring}`}>
                    <div><i className="icon-engine-status"></i></div>
                    <div>Engine Status</div>
                </Link>
            </li>
            <li className={selectedSection==='itinerary' ? 'selected' : ''} onClick={()=>handleSelectSection('itinerary')}>
                  <Link to={`/live/ship/itinerary/${window.qstring}`}>
                    <div><i className="icon-voyage-info"></i></div>
                    <div>Voyage Info</div>
                </Link>
            </li>
        </ul>
    </footer>
  );
}

export default Footer;